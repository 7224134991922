@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10deg);
  }
  
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes transitionOut {
  from {
    opacity: 1;
    transform: rotateX(0);
  }
  
  to {
     opacity: 0;
    transform: rotateX(-10deg);
  }
}


@layer base{
  li {
    @apply pl-10;
    @apply cursor-pointer;
  }
}


.desktop-pdf {
    display: none;
}

.mobile-pdf {
    display: grid;
    padding-left: 12%;
    padding-right: 5%;
}
@media only screen  and (min-width : 768px) {

  .mobile-pdf {
      display: none;
  }

  .desktop-pdf {
      display: block;
      width: 600;
      padding-left: 20%;
      padding-right: 25%;
  }
}